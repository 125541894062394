<template>
    <div>
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="settingsContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            to="/"
                        >
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <h2>
                            {{ $t("setting.title") }}
                        </h2>
                    </v-app-bar>
                </header>
                <v-list v-if="pinsecured">
                    <h1>
                        API
                    </h1>
                    <v-list-item>
                        <v-text-field
                            v-model="settings.apiUrl"
                            clearable
                            :label="$t('setting.url')"
                            @change="saveToLocalStorage"
                            @input="checkSettings"
                        />
                    </v-list-item>
                    <v-list-item>
                        <v-text-field
                            v-model="settings.apiUsername"
                            clearable
                            :label="$t('setting.user')"
                            @change="saveToLocalStorage"
                            @input="checkSettings"
                        />
                    </v-list-item>
                    <v-list-item>
                        <v-text-field
                            v-model="settings.apiPassword"
                            :type="passwordFieldType"
                            clearable
                            :label="$t('setting.pw')"
                            @change="saveToLocalStorage"
                            @input="checkSettings"
                        />
                        <button
                            type="password"
                            @click="switchPasswordVisibility"
                        >
                            <v-icon medium>remove_red_eye</v-icon>
                        </button>
                    </v-list-item>
                    <v-list-item>
                        <v-select
                            v-model="$i18n.locale"
                            :items="langs"
                            :label="$t('setting.lang')"
                        >
                            <template
                                slot="selection"
                                slot-scope="data"
                            >
                                <v-avatar size="20px" tile>
                                    <img :src="data.item.avatar">
                                </v-avatar>
                                <div class="marg-left-10">
                                    {{ data.item.text }}
                                </div>
                            </template>
                            <template
                                slot="item"
                                slot-scope="data"
                            >
                                <v-list-item-avatar tile>
                                    <img :src="data.item.avatar">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ data.item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-select>
                    </v-list-item>
                    <v-list-item>
                        <v-text-field
                            v-model="settings.pincode"
                            :type="pinFieldType"
                            label="Pin"
                            clearable
                            @change="saveToLocalStorage"
                        />
                        <button
                            type="password"
                            @click="switchPinVisibility"
                        >
                            <v-icon medium>remove_red_eye</v-icon>
                        </button>
                    </v-list-item>
                    <v-list-item>
                        <v-switch
                            v-model="signature"
                            :label="$t('setting.signature')"
                            @change="saveToLocalStorage"
                        ></v-switch>
                    </v-list-item>
                    <v-list-item>
                        <v-switch
                            v-model="settings.uploadOnlySigned"
                            :disabled="!settings.signature"
                            :label="$t('setting.uploadOnlySigned')"
                            @change="saveToLocalStorage"
                        ></v-switch>
                    </v-list-item>
                    <v-list-item>
                        <v-btn
                            color="error"
                            :disabled="noOrders"
                            @click="removeOrdersDialog = !removeOrdersDialog"
                        >
                            {{$t('setting.removeOrders')}}
                        </v-btn>
                    </v-list-item>
                </v-list>
                <pinDialog ref="pinCodeDialog"/>
                <v-dialog
                    v-model="removeOrdersDialog"
                    persistent
                    max-width="300"
                    height="200px"
                >
                    <v-card>
                        <v-card-text
                            center
                        >
                            <h1>
                                {{ $t("setting.removeOrders") }}
                            </h1>
                            <h2>
                                {{ $t("devicepage.sure") }}
                            </h2>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="green darken-1"
                                width="120"
                                @click.native="agree()"
                            >
                                <icon
                                    name="fa/solid/check"
                                    class="barIcon"
                                    color="white"
                                />
                            </v-btn>
                            <v-btn
                                color="red darken-1"
                                width="130"
                                @click.native="removeOrdersDialog = false"
                            >
                                <icon
                                    name="fa/solid/times"
                                    class="barIcon"
                                    color="white"
                                />
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>
        </v-app>
    </div>
</template>
<script>
import pinDialog from '../components/pinDialog'
import Icon, { register as registerIcons } from '@wisol/icons'
import CheckIcon from '@wisol/icons/src/icons/fa/solid/check'
import TimesIcon from '@wisol/icons/src/icons/fa/solid/times'

registerIcons(CheckIcon, TimesIcon)

export default {
    name: 'Settings',
    components: {
        pinDialog,
        Icon
    },
    data () {
        return {
            settings: {},
            disableGoBack: false,
            pinsecured: false,
            noOrders: true,
            removeOrdersDialog: false,
            passwordFieldType: 'password',
            pinFieldType: 'password',
            langs: [
                { text: 'Français', value: 'fr', avatar: 'french.svg' },
                { text: 'Deutsch', value: 'de', avatar: 'german.svg' },
                { text: 'English', value: 'en', avatar: 'english.svg' },
                { text: 'Lëtzebuergesch', value: 'lu', avatar: 'lu.svg' }
            ]
        }
    },
    created () {
        window.addEventListener('beforeunload', this.saveToLocalStorage)
        this.loadFromLocalStorage()
    },
    mounted () {
        this.checkSettings()
        this.openPinDialog()
    },
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.saveToLocalStorage)
    },
    computed: {
        signature: {
            get () {
                return this.settings.signature
            },
            set (newValue) {
                this.settings.signature = newValue
                if (newValue === false) {
                    this.settings.uploadOnlySigned = newValue
                }
            }
        }
    },
    methods: {
        openPinDialog () {
            const pinDialog = this.$refs.pinCodeDialog
                .open(
                    this.settings.pincode
                )
                .then(() => {
                    this.pinsecured = true
                })
            return pinDialog
        },
        checkSettings () {
            // check Settings
            if (
                !this.settings.apiUsername ||
                !this.settings.apiPassword ||
                !this.settings.apiUrl) {
                this.disableGoBack = true
            } else {
                this.disableGoBack = false
            }
        },
        saveToLocalStorage () {
            // save to localStorage
            localStorage.setItem('settings', JSON.stringify(this.settings))
        },
        switchPinVisibility () {
            // show Pin
            this.pinFieldType = this.pinFieldType === 'password' ? 'text' : 'password'
        },
        switchPasswordVisibility () {
            // show Password
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        loadFromLocalStorage () {
            // load from localStorage
            const settings = localStorage.getItem('settings')
            if (settings) {
                this.settings = {
                    ...this.settings,
                    ...JSON.parse(settings)
                }
            }
            const orders = JSON.parse(localStorage.getItem('orders'))
            if (orders) {
                this.noOrders = false
            }
        },
        agree () {
            this.removeOrdersDialog = false
            localStorage.removeItem('orders')
            this.noOrders = true
        }
    }
}
</script>

<style>
.gradient {
  background: linear-gradient(90deg, #CC6633, #B9131D, #781345, #442254);
  padding-bottom: 10px;
}
.marg-left-10 {
    margin-left: 10px;
}
.settingsContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.settingsContainer header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.v-card__subtitle, .v-card__text, .v-card__title{
    padding: 10px !important;
}
</style>
