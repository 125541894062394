<template>
    <v-dialog
        v-model="show"
        min-width="250"
        max-width="300"
    >
        <slot>
            <v-card>
                <v-alert
                    :value="invalidPin"
                    type="error"
                >
                    {{ $t("setting.wrongPin") }}
                </v-alert>
                <v-card-text>
                    <v-text-field
                        v-model="input"
                        type="password"
                        ref="pinCodeDialog"
                        color="red"
                        label="Pin"
                        @keyup.enter="checkPin"
                    />
                    <v-btn
                        color="green darken-1"
                        dark
                        width="120"
                        @click="checkPin"
                    >
                        ok
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        dark
                        width="130"
                        to="/"
                        @click="close"
                    >
                        {{ $t("setting.cancel") }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </slot>
    </v-dialog>
</template>

<script>
export default {
    name: 'pinDialog',
    data () {
        return {
            show: false,
            invalidPin: false,
            pinCode: null,
            resolve: null,
            reject: null,
            input: null
        }
    },
    methods: {
        open (pincode) {
            this.show = true
            this.pinCode = pincode
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        close () {
            this.input = null
            this.show = false
            this.invalidPin = false
        },
        checkPin () {
            if (this.pinCode === this.input) {
                this.resolve(this.input)
                this.show = false
            } else {
                this.invalidPin = true
            }
        }
    }
}
</script>
